import classNames from "classnames";
import { useTranslation } from "next-i18next";
import Image from "next/image";
import { FC } from "react";
import Button from "~/components/Button";
import { Container } from "~/components/Container";
import Text from "~/components/Text";
import { entrepreneurFeatures } from "~/constants/data";
import Trans from "~/types/translationKeys";
import styles from "./FeaturesLong.module.scss";

interface Feature {
  icon?: string;
  name: string;
  description: string;
  image: string;
}

const FeaturesLong: FC = () => {
  const { t } = useTranslation();

  return (
    <div id="features" className={styles.features}>
      <Container className={styles.container}>
        <div className="flex flex-col gap-4">
          <div
            className={styles.description}
            data-aos="fade-up"
            dangerouslySetInnerHTML={{
              __html: `${t(Trans.everything_you_need_in_just_1_place)}`,
            }}
          />
          <Text type="headline-4" className={styles.title} data-aos="fade-up">
            {t(Trans.leverage_the_power_of_ai_to_outperform_those_who_dont)}
          </Text>
        </div>

        <div className={styles.items_container} data-aos="fade-up">
          {entrepreneurFeatures.map((feature: Feature, index: number) => {
            const isEven = index % 2 == 0;
            return (
              <div
                key={feature.name}
                className={classNames(
                  styles.section,
                  isEven ? styles.left : styles.right
                )}
              >
                <div className={styles.info}>
                  <Image
                    height={48}
                    width={48}
                    alt={"Icon"}
                    src={feature.icon ?? ""}
                  />
                  <div className="flex flex-col gap-3">
                    <Text className={styles.info_title} type={"headline-5"}>
                      {t(feature.name)}
                    </Text>
                    <Text className={styles.info_description} type={"body-2"}>
                      {t(feature.description)}
                    </Text>
                  </div>
                </div>
                <div className={"grow w-full"}>
                  <img src={feature.image ?? ""} className={styles.image} />
                </div>
              </div>
            );
          })}
        </div>

        <div data-aos="fade-up" className={styles.integration}>
          <div className="relative aspect-[535/436] flex-1 max-w-[535px]">
            <Image
              className="object-contain"
              src="/svgs/feature_integration.svg"
              alt="Feature Integration"
              fill
            />
          </div>

          <div className={styles.integration_text_container}>
            <div className="flex flex-col gap-3">
              <h1 className={styles.integration_title}>
                {t(Trans.integration_title)}
              </h1>
              <p className={styles.integration_desc}>
                {t(Trans.integration_desc)}
              </p>
            </div>

            <div className={styles.integration_cta}>
              <Text
                type={"headline-4"}
                className={styles.integration_cta_title}
              >
                {t(Trans.start_your_free_14_day_trial)}
              </Text>
              <div
                dangerouslySetInnerHTML={{
                  __html: `${t(Trans.just_14_99_month_after)}`,
                }}
                className={styles.integration_cta_description}
              />
              <Button>{t(Trans.start_for_free)}</Button>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default FeaturesLong;
