import { useTranslation } from "next-i18next";
import Image from "next/image";
import Link from "next/link";
import { useRouter } from "next/router";
import { FC } from "react";
import { useCookies } from "react-cookie";
import Button from "~/components/Button";
import { Container } from "~/components/Container";
import InfiniteScroll from "~/components/InfiniteScroll";
import Trans from "~/types/translationKeys";
import { getQueryParams } from "~/utils/common";
import styles from "./Hero.module.scss";

const Hero: FC = () => {
  const router = useRouter();
  const { t } = useTranslation();
  const { utm_source, utm_campaign } = router.query;
  const isBlog = router.asPath.includes("resources/b");
  const [cookie, _] = useCookies(["utm_source", "utm_blog", "others"]);

  return (
    <section className={styles.hero}>
      <Container>
        <div className="flex justify-center w-full mb-3">
          {/* <a
            href="https://www.producthunt.com/posts/cx-genie-2?embed=true&utm_source=badge-featured&utm_medium=badge&utm_souce=badge-cx&#0045;genie&#0045;2"
            target="_blank"
          >
            <img
              src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=486201&theme=light"
              alt="CX&#0032;Genie - Transform&#0032;customer&#0032;support&#0032;with&#0032;no&#0045;code&#0032;AI&#0045;powered&#0032;solutions | Product Hunt"
              width="200"
              height="39"
            />
          </a> */}
          <a
            href="https://www.producthunt.com/posts/cx-genie-2?embed=true&utm_source=badge-top-post-badge&utm_medium=badge&utm_souce=badge-cx&#0045;genie&#0045;2"
            target="_blank"
          >
            <img
              src="https://api.producthunt.com/widgets/embed-image/v1/top-post-badge.svg?post_id=486201&theme=light&period=daily"
              alt="CX&#0032;Genie - Transform&#0032;customer&#0032;support&#0032;with&#0032;no&#0045;code&#0032;AI&#0045;powered&#0032;solutions | Product Hunt"
              width="200"
              height="39"
            />
          </a>
        </div>
        <h1 className={styles.title}>
          Never Miss a Lead Again &#45; Your AI Customer Support for Lean Businesses
        </h1>
        <span className={styles.subtitle}>
          Deliver professional, 24/7 support that scales with your business, even without a dedicated customer service team.
        </span>
        <div className={styles.cta}>
          <Link
            type="outline"
            href={{
              pathname: `${process.env.NEXT_PUBLIC_DASHBOARD_URL}/sign-up`,
              query: {
                utm_source: isBlog
                  ? `${cookie["utm_source"] || "blog"}`
                  : `${cookie["utm_source"] || utm_source || ""}`,
                utm_campaign,
                utm_blog: isBlog ? cookie["utm_blog"] : "",
                others: cookie["others"] || getQueryParams(router.asPath),
              },
            }}
            target="_blank"
          >
            <Button>{t(Trans.start_for_free)}</Button>
          </Link>
          <Button
            type="outline"
            variant="secondary"
            onClick={() => {
              (window as any)?.demo_conversion?.();
              window.open(
                "https://calendar.app.google/9YB8GKe3ZgNt1z3z7",
                "_blank"
              );
            }}
          >
            {t(Trans.book_demo)}
          </Button>
        </div>
      </Container>
      <div style={{ height: 144 }} />
      <InfiniteScroll
        height={447}
        items={[
          <div key="Image" className="relative aspect-[351/447] h-full">
            <Image src="/hero/1.png" alt="Hero 1" fill />
          </div>,
          <div
            key="Image"
            className="relative aspect-[879/447] h-full overflow-visible"
          >
            <Image src="/hero/2.png" alt="Hero 1" fill />
            <Image
              src="/hero/2_1.png"
              alt="Hero 1"
              width={261}
              height={80}
              style={{
                position: "absolute",
                left: -124,
                top: 179,
                boxShadow: "6px 16px 36px 0px rgba(0, 0, 0, 0.10)",
                borderRadius: 12,
              }}
            />
            <Image
              src="/hero/2_2.png"
              alt="Hero 1"
              width={243}
              height={106}
              style={{
                position: "absolute",
                left: 119,
                top: -77,
                boxShadow: "6px 16px 36px 0px rgba(0, 0, 0, 0.10)",
                borderRadius: 12,
              }}
            />
            <Image
              src="/hero/2_3.png"
              alt="Hero 1"
              width={220}
              height={482}
              style={{
                position: "absolute",
                right: -40,
                top: -98,
                zIndex: 2,
                boxShadow: "6px 16px 36px 0px rgba(0, 0, 0, 0.10)",
                borderRadius: 12,
              }}
            />
          </div>,
          <div key="Image" className="relative aspect-[691/447] h-full z-1">
            <Image src="/hero/3.png" alt="Hero 1" fill />
            <Image
              src="/hero/3_1.png"
              alt="Hero 1"
              width={261}
              height={80}
              style={{
                position: "absolute",
                left: 38,
                bottom: -15,
                boxShadow: "6px 16px 36px 0px rgba(0, 0, 0, 0.10)",
                borderRadius: 12,
              }}
            />
            <Image
              src="/hero/3_2.png"
              alt="Hero 1"
              width={261}
              height={80}
              style={{
                position: "absolute",
                left: 185,
                top: -53,
                boxShadow: "6px 16px 36px 0px rgba(0, 0, 0, 0.10)",
                borderRadius: 12,
              }}
            />
            <Image
              src="/hero/3_3.png"
              alt="Hero 1"
              width={251}
              height={93}
              style={{
                position: "absolute",
                right: -182,
                top: 224,
                boxShadow: "6px 16px 36px 0px rgba(0, 0, 0, 0.10)",
                borderRadius: 12,
                zIndex: 2,
              }}
            />
          </div>,
          <div key="Image" className="relative aspect-[813/447] h-full">
            <Image src="/hero/4.png" alt="Hero 1" fill />
            <Image
              src="/hero/4_1.png"
              alt="Hero 1"
              width={261}
              height={80}
              style={{
                position: "absolute",
                left: 171,
                top: -40,
                boxShadow: "6px 16px 36px 0px rgba(0, 0, 0, 0.10)",
                borderRadius: 12,
              }}
            />
            <Image
              src="/hero/4_2.png"
              alt="Hero 1"
              width={261}
              height={80}
              style={{
                position: "absolute",
                right: -112,
                top: 237,
                boxShadow: "6px 16px 36px 0px rgba(0, 0, 0, 0.10)",
                borderRadius: 12,
                zIndex: 2,
              }}
            />
          </div>,
          <div key="Image" className="relative aspect-[459/447] h-full">
            <Image src="/hero/5.png" alt="Hero 1" fill />
          </div>,
          <div key="Image" className="relative aspect-[435/447] h-full">
            <Image src="/hero/6.png" alt="Hero 1" fill />
            <Image
              src="/hero/6_1.png"
              alt="Hero 1"
              width={223}
              height={180}
              style={{
                position: "absolute",
                left: -178,
                top: -53,
                boxShadow: "6px 16px 36px 0px rgba(0, 0, 0, 0.10)",
                borderRadius: 12,
                zIndex: 2,
              }}
            />
            <Image
              src="/hero/6_2.png"
              alt="Hero 1"
              width={223}
              height={180}
              style={{
                position: "absolute",
                right: -44,
                top: 198,
                boxShadow: "6px 16px 36px 0px rgba(0, 0, 0, 0.10)",
                borderRadius: 12,
                zIndex: 2,
              }}
            />
          </div>,
        ]}
      />
    </section>
  );
};

export default Hero;
