import { animate, motion, useMotionValue } from "framer-motion";
import React, { ReactNode, useEffect, useState } from "react";
import useMeasure from "react-use-measure";
import styles from "./InfiniteScroll.module.scss";

const FAST_DURATION = 25;
const SLOW_DURATION = 75;

interface InfiniteScrollProps {
  items: ReactNode[];
  height?: number;
}

const InfiniteScroll: React.FC<InfiniteScrollProps> = ({
  items,
  height = 447,
}) => {
  let [ref, { width }] = useMeasure();

  const xTransition = useMotionValue(0);
  const [duration, setDuration] = useState(FAST_DURATION);
  const [mustFinish, setMustFinish] = useState(false);
  const [rerender, setRerender] = useState(false);

  useEffect(() => {
    let controls;
    let finalPosition = -width / 2 - 24;

    if (mustFinish) {
      controls = animate(xTransition, [xTransition.get(), finalPosition], {
        ease: "linear",
        duration: duration * (1 - xTransition.get() / finalPosition),
        onComplete: () => {
          setMustFinish(false);
          setRerender(!rerender);
        },
      });
    } else {
      controls = animate(xTransition, [0, finalPosition], {
        ease: "linear",
        duration: duration,
        repeat: Infinity,
        repeatType: "loop",
        repeatDelay: 0,
      });
    }

    return controls?.stop;
  }, [xTransition, width, duration, rerender]);

  return (
    <div className={styles.scroll_container} style={{ height }}>
      <motion.div
        className={styles.scroll_track}
        ref={ref}
        style={{ x: xTransition }}
        onHoverStart={() => {
          setMustFinish(true);
          setDuration(SLOW_DURATION);
        }}
        onHoverEnd={() => {
          setMustFinish(true);
          setDuration(FAST_DURATION);
        }}
      >
        {[...items, ...items].map((item, index: number) => (
          <div key={index}>{item}</div>
        ))}
      </motion.div>
    </div>
  );
};

export default InfiniteScroll;
